import React, { useEffect } from 'react';
import { useState } from 'react';
import HeaderDropdown from "../../components/menu/HeaderDropdown"
import ApiService from '../../services/api.service'
import { Link } from 'react-router-dom'
import ShimmerLoading from '../../components/shimmerLoading';

export default function Dashboard() {
    const [isFetchingData, setIsFetchingData] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setIsFetchingData(true);
            ApiService.init();
    
            try {
                const response = await ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/dashboard', {});
                const data = response.data;
    
                const today = new Date();
                const target = new Date(data.expiredAt);
                const diffTime = Math.abs(target - today);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
                setData({
                    'expiredAt': diffDays,
                    'totalBranch': data.totalBranch,
                    'totalCampaign': data.totalCampaign,
                    'totalCampaignActive': data.totalCampaignActive,
                    'totalCampaignInactive': data.totalCampaignInactive,
                    'totalDevice': data.totalDevice,
                    'totalDeviceActive': data.totalDeviceActive,
                    'totalDeviceInactive': data.totalDeviceInactive,
                    'totalMeeting': data.totalMeeting,
                    'totalMeetingActive': data.totalMeetingActive,
                    'totalMeetingInactive': data.totalMeetingInactive,
                    'totalPerProvince': data.totalPerProvince,
                });
            } catch (error) {
                if (error.response && error.response.status >= 400 && error.response.status < 500) {
                    console.error('Client error, redirecting to login.');
                    window.location.href = '/login';
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsFetchingData(false);
            }
        };
    
        fetchData();
    }, []);    

    const [data, setData] = useState({
        'expiredAt': '',
        'totalBranch': 0,
        'totalCampaign': 0,
        'totalCampaignActive': 0,
        'totalCampaignInactive': 0,
        'totalDevice': 0,
        'totalDeviceActive': 0,
        'totalDeviceInactive': 0,
        'totalMeeting': 0,
        'totalMeetingActive': 0,
        'totalMeetingInactive': 0,
        'totalPerProvince': {}
    });

    function TablePerProvince() {
        let tableData = [];

        if (data.totalPerProvince !== undefined) {

            for (let i = 0; i < data.totalPerProvince.length; i++) {
                tableData.push(
                    <tr key={i + 'data'}>
                        <td>{data.totalPerProvince[i].province == null ? 0 : data.totalPerProvince[i].province}</td>
                        <td>{data.totalPerProvince[i].totalBranch == null ? 0 : data.totalPerProvince[i].totalBranch}</td>
                        <td>{data.totalPerProvince[i].totalCampaign == null ? 0 : data.totalPerProvince[i].totalCampaign}</td>
                        <td>{data.totalPerProvince[i].totalDevice == null ? 0 : data.totalPerProvince[i].totalDevice}</td>
                        <td>{data.totalPerProvince[i].totalMeeting == null ? 0 : data.totalPerProvince[i].totalMeeting}</td>
                    </tr>
                )
            }
        }
        return tableData
    }


    if (localStorage.getItem("role") === 'Superadmin') {
        window.location = '/dashboard'
    } else {
        return (
            <div className="w-screen dashboard bg-white min-h-screen">
                <div className='top-content'>
                    <div className='navbar flex'>
                        <div className='notif'>
                            <div className='flex flex-wrap'>
                                <div className="flex items-center basis-10/12">
                                    <p>Masa percobaan Anda akan berakhir dalam {data.expiredAt} hari. Upgrade akun Anda agar dapat kembali menikmati layanan Skrin</p>
                                </div>
                                <div className="flex items-center basis-2/12">
                                    <a href={process.env.REACT_APP_AWD_DOMAIN + '/dashboard'} className="w-full">
                                        <button type="button" className="btn btn-outline"> UPGRADE </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="menu grid grid-cols-2 !bg-[transparent] !border-[none] o!utline-[none] !shadow-none">
                            <div className="flex justify-center">
                                <a href={process.env.REACT_APP_AWD_DOMAIN + '/dashboard'}>
                                    <button className="btn btn-menu !shadow-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.0001 5.85001C20.0001 7.20864 18.8987 8.31001 17.5401 8.31001C16.1815 8.31001 15.0801 7.20863 15.0801 5.85001C15.0801 4.49139 16.1815 3.39001 17.5401 3.39001C18.8987 3.39001 20.0001 4.49139 20.0001 5.85001Z" fill="white" stroke="white" />
                                            <path d="M8.92 5.85001C8.92 7.20864 7.81862 8.31001 6.46 8.31001C5.10138 8.31001 4 7.20864 4 5.85001C4 4.49139 5.10138 3.39001 6.46 3.39001C7.81862 3.39001 8.92 4.49139 8.92 5.85001Z" fill="white" stroke="white" />
                                            <path d="M20.0001 18.1499C20.0001 19.5086 18.8987 20.6099 17.5401 20.6099C16.1815 20.6099 15.0801 19.5086 15.0801 18.1499C15.0801 16.7913 16.1815 15.6899 17.5401 15.6899C18.8987 15.6899 20.0001 16.7913 20.0001 18.1499Z" fill="white" stroke="white" />
                                            <path d="M6.46 21.1099C8.09476 21.1099 9.42 19.7847 9.42 18.1499C9.42 16.5152 8.09476 15.1899 6.46 15.1899C4.82524 15.1899 3.5 16.5152 3.5 18.1499C3.5 19.7847 4.82524 21.1099 6.46 21.1099Z" fill="white" />
                                        </svg>
                                    </button>
                                </a>
                            </div>
                            <HeaderDropdown/>
                        </div>
                    </div>
                    <div className='message'>
                        <h3>Halo, {localStorage.getItem('name')}! 👋🏻</h3>
                        <p>Ini adalah halaman semua informasi yang Anda butuhkan</p>
                    </div>
                </div>
                {isFetchingData ? (
                    <div className='absolute top-[229px] mx-4 left-0 right-0'>
                      <div className="h-[256px]">
                        <ShimmerLoading/>
                      </div>
                    </div>
                ) : (
                    <>
                        <div className='middle-content middle-content-user flex flex-wrap'>
                            <div className="basis-2/12 cabang-info">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.00977 11.22V15.71C3.00977 20.2 4.80977 22 9.29977 22H14.6898C19.1798 22 20.9798 20.2 20.9798 15.71V11.22" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <p>Total Cabang</p>
                                <h3>
                                    {data.totalBranch == null ? 0 : data.totalBranch}
                                </h3>

                                <Link to="/cabang">
                                    + Add Branch
                                </Link>
                            </div>
                            <div className="basis-10/12 cabang-table">
                                <table className="table w-full">
                                    <thead>
                                        <tr>
                                            <th>Provinsi</th>
                                            <th>Total Cabang</th>
                                            <th>Total Perangkat</th>
                                            <th>Total Ruang Meeeting</th>
                                            <th>Total Campaign</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <TablePerProvince />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='bottom-content'>
                            <div className='grid grid-cols-2 gap-x-[38px]'>
                                {/*<div className='card'>
                                    <div className="flex flex-wrap">
                                        <div className='basis-6/12'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.9699 14.4399C18.3399 14.6699 19.8499 14.4299 20.9099 13.7199C22.3199 12.7799 22.3199 11.2399 20.9099 10.2999C19.8399 9.58992 18.3099 9.34991 16.9399 9.58991" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M5.96998 7.16C6.02998 7.15 6.09998 7.15 6.15998 7.16C7.53998 7.11 8.63998 5.98 8.63998 4.58C8.63998 3.15 7.48998 2 6.05998 2C4.62998 2 3.47998 3.16 3.47998 4.58C3.48998 5.98 4.58998 7.11 5.96998 7.16Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.99994 14.4399C5.62994 14.6699 4.11994 14.4299 3.05994 13.7199C1.64994 12.7799 1.64994 11.2399 3.05994 10.2999C4.12994 9.58992 5.65994 9.34991 7.02994 9.58991" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.0001 14.63C11.9401 14.62 11.8701 14.62 11.8101 14.63C10.4301 14.58 9.33008 13.45 9.33008 12.05C9.33008 10.62 10.4801 9.46997 11.9101 9.46997C13.3401 9.46997 14.4901 10.63 14.4901 12.05C14.4801 13.45 13.3801 14.59 12.0001 14.63Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.09021 17.7799C7.68021 18.7199 7.68021 20.2599 9.09021 21.1999C10.6902 22.2699 13.3102 22.2699 14.9102 21.1999C16.3202 20.2599 16.3202 18.7199 14.9102 17.7799C13.3202 16.7199 10.6902 16.7199 9.09021 17.7799Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='basis-6/12 flex justify-end'>
                                            <Link to="/ruang-meeting/create-board">
                                                + Tambah Ruang
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="title">
                                        <p>
                                            Total Ruang Meeting
                                        </p>
                                        <h4>
                                            {data.totalMeeting == null ? 0 : data.totalMeeting}
                                        </h4>
                                    </div>
                                    <div className="flex flex-wrap status">
                                        <div className='basis-6/12 left'>
                                            <h1>
                                                {data.totalMeetingActive == null ? 0 : data.totalMeetingActive}
                                            </h1>
                                            <p>
                                                Aktif
                                            </p>
                                        </div>
                                        <div className='basis-6/12 right'>
                                            <h1>
                                                {data.totalMeetingInactive == null ? 0 : data.totalMeetingInactive}
                                            </h1>
                                            <p>
                                                Tidak Aktif
                                            </p>
                                        </div>
                                    </div>
                                </div>*/}
                                <div className='card'>
                                    <div className="flex flex-wrap">
                                        <div className='basis-6/12'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.66992 18.9501L7.59992 15.6401C8.38992 15.1101 9.52992 15.1701 10.2399 15.7801L10.5699 16.0701C11.3499 16.7401 12.6099 16.7401 13.3899 16.0701L17.5499 12.5001C18.3299 11.8301 19.5899 11.8301 20.3699 12.5001L21.9999 13.9001" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='basis-6/12 flex justify-end'>
                                            <Link to="campaign/template">
                                                + Tambah Campaign
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="title">
                                        <p>
                                            Total Campaign
                                        </p>
                                        <h4>
                                            {data.totalCampaign == null ? 0 : data.totalCampaign}
                                        </h4>
                                    </div>
                                    <div className="flex flex-wrap status">
                                        <div className='basis-6/12 left'>
                                            <h1>
                                                {data.totalCampaignActive == null ? 0 : data.totalCampaignActive}
                                            </h1>
                                            <p>
                                                Aktif
                                            </p>
                                        </div>
                                        <div className='basis-6/12 right'>
                                            <h1>
                                                {data.totalCampaignInactive == null ? 0 : data.totalCampaignInactive}
                                            </h1>
                                            <p>
                                                Tidak Aktif
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className="flex flex-wrap">
                                        <div className='basis-6/12'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.6 18.5H16.4C17.9 18.5 18.5 17.9 18.5 16.4V14.6C18.5 13.1 17.9 12.5 16.4 12.5H12.6C11.1 12.5 10.5 13.1 10.5 14.6V16.4C10.5 17.9 11.1 18.5 12.6 18.5Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='basis-6/12 flex justify-end'>

                                        </div>
                                    </div>

                                    <div className="title">
                                        <p>
                                            Total Perangkat
                                        </p>
                                        <h4>
                                            {data.totalDevice == null ? 0 : data.totalDevice}
                                        </h4>
                                    </div>
                                    <div className="flex flex-wrap status">
                                        <div className='basis-6/12 left'>
                                            <h1>
                                                {data.totalDeviceActive == null ? 0 : data.totalDeviceActive}
                                            </h1>
                                            <p>
                                                Aktif
                                            </p>
                                        </div>
                                        <div className='basis-6/12 right'>
                                            <h1>
                                                {data.totalDeviceInactive == null ? 0 : data.totalDeviceInactive}
                                            </h1>
                                            <p>
                                                Tidak Aktif
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}